<template>
    <div>
        <div v-if="tableData" class="space-y-4">
            <div class="flex items-center space-x-3 pt-4 min-h-14" :class="narrow ? 'mx-4' : 'mx-6'">
                <h4 class="text-lg font-medium my-auto flex-1">{{ tableData.title }}</h4>
                <span v-if="tableData.searchbar" class="text-right w-80">
                    <SearchInput v-model:value="search" :label="tableData.searchbar.label"></SearchInput>
                </span>
                <a v-if="helpLink" :href="helpLink" target="_blank" class="flex flex-shrink-0 items-center justify-center p-1 rounded-full transition-colors hover:bg-gray-100">
                    <span class="sr-only">{{ helpLink }}</span>
                    <Icon class="h-6 w-auto" :src="IconSource.HelpCircleIcon"></Icon>
                </a>
            </div>
            <div class="overflow-x-auto">
                <CustomTable :headers="headers" :items="filteredItems" :narrow="narrow">
                    <template v-if="tableData.footers && tableData.footers.length" #append-rows="{ getWidth, getHeaderClass }">
                        <tr class="border-t border-black flex py-4 px-6 gap-x-4 odd:bg-dark-gray-200">
                            <td
                                v-for="header of tableData.headers"
                                :key="header.value"
                                :class="`${getHeaderClass(header, false)} my-auto break-words min-h-6.5 font-medium`"
                                :style="`max-width: ${getWidth(header)}px; width: 100%;`"
                            >
                                {{ styleFooterCell(header, tableData.footers) }}
                            </td>
                        </tr>
                    </template>
                </CustomTable>
            </div>
        </div>
        <div v-else class="text-center w-full font-semibold py-4" :class="narrow ? 'px-4' : 'px-6'">
            {{ $t('global.tables.emptyTableMessage') }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import CustomTable from '@/components/ui/CustomTable.vue';
import SearchInput from '@/components/ui/SearchInput.vue';
import { GenericTableOptions, TableHeader, IconSource } from '@/types';
import useFilterableTable from '@/composables/useFilterableTable';
import { i18n } from '@/plugins/internationalization/i18n';

export default defineComponent({
    components: { CustomTable, SearchInput },
    props: {
        tableData: {
            type: Object as () => GenericTableOptions,
            default: null,
        },
        narrow: {
            type: Boolean,
            default: false,
        },
        helpLink: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const headers = computed(() => props.tableData?.headers || []);
        const items = computed(() => props.tableData?.data || []);
        const { search, filteredItems } = useFilterableTable(items, headers);

        function styleFooterCell(
            header: TableHeader,
            footers: {
                value: string;
                text: string;
                align: string;
                compute_callback: string;
            }[]
        ) {
            const footer = footers.find((currentFooterColumn) => currentFooterColumn.value === header.value);
            if (typeof footer?.text === 'number') {
                return i18n.global.n(Number(footer!.text));
            }
            return footer?.text || '';
        }

        return {
            headers,
            search,
            filteredItems,
            styleFooterCell,
            IconSource,
        };
    },
});
</script>
