<template>
    <DashboardContentLayout heading="" :has-period-picker="false" :has-pig-type-picker="false">
        <DataQualityBar v-if="hasDataQualityAssessmentModuleEnabled" />
        <div class="space-y-8">
            <KpiGrid></KpiGrid>
            <DashboardContentHeader :heading="$t('views.overview.header.heading')" :has-period-picker="true" :has-pig-type-picker="true"></DashboardContentHeader>
            <GenericDashboard :data="dashboardData" :loading="isDashboardLoading" :fetch-action-type="ActionType.GetDashboardOverview" :state-mutation-type="MutationType.SetDashboardOverview" />
        </div>
    </DashboardContentLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import DashboardContentLayout from '@/components/layouts/dashboardLayout/content/DashboardContentLayout.vue';
import KpiGrid from '@/views/dashboard/overview/KpiGrid.vue';
import DashboardContentHeader from '@/components/layouts/dashboardLayout/content/DashboardContentHeader.vue';
import DataQualityBar from './DataQualityBar.vue';
import { store } from '@/plugins/store';
import { Module } from '@/types';
import useRefetchDataOnDashboardStateChanged from '@/components/common/composables/useRefetchDataOnDashboardStateChanged';
import { ActionType } from '@/plugins/store/actions';
import GenericDashboard from '@/components/common/dashboard/GenericDashboard.vue';
import { MutationType } from '@/plugins/store/mutations';

useRefetchDataOnDashboardStateChanged(ActionType.GetDashboardOverview, { watchSites: true });

const hasDataQualityAssessmentModuleEnabled = computed(() => store.getters.hasModuleEnabled(Module.DataQualityAssessment));
const dashboardData = computed(() => store.state.dashboardOverview);
const isDashboardLoading = computed(() => store.state.dashboardOverviewLoading);
</script>
