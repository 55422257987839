<template>
    <transition name="fade" appear>
        <div v-if="computedData">
            <div v-if="computedData.sections?.length" class="space-y-6">
                <div v-for="(section, sectionIndex) in computedData.sections" :key="`section-${sectionIndex}`" class="border border-gray-300 rounded-lg px-4 py-5 space-y-5">
                    <div class="flex items-center justify-between gap-x-3">
                        <div class="space-y-1">
                            <div class="font-bold text-lg leading-tight">
                                {{ section.title }}
                            </div>
                            <div v-if="section.subtitle" class="text-gray-500 leading-tight">
                                {{ section.subtitle }}
                            </div>
                        </div>
                        <div v-if="section.is_collapsible" class="flex-shrink-0">
                            <button type="button" class="p-3 flex items-center justify-center rounded-full transition-colors hover:bg-gray-200/70 duration-200" @click="toggleSectionCollapse(sectionIndex)">
                                <Icon class="h-4 w-4 transform transition-transform rotate-90 cursor-pointer" :class="{ 'rotate-[270deg]': !section.is_collapsed }" :src="IconSource.Right"></Icon>
                            </button>
                        </div>
                    </div>
                    <ExpandCollapseTransition>
                        <div v-if="!section.is_collapsed || !section.is_collapsible" class="grid grid-cols-2 items-stretch gap-4">
                            <GenericDashboardElement
                                v-for="item in (section as DashboardSectionWithItems).items"
                                :key="item.item_no"
                                :item="item"
                                :loading="loadingState[item.item_no]"
                                @update:options="onChartOptionsUpdate($event, item.item_no)"
                                @update:chart="setChartInstance($event, item.item_no)"
                            />
                        </div>
                    </ExpandCollapseTransition>
                </div>
            </div>
            <div v-else class="grid grid-cols-2 items-stretch gap-4">
                <GenericDashboardElement
                    v-for="item in (computedData as Dashboard).items"
                    :key="item.item_no"
                    :item="item"
                    :loading="loadingState[item.item_no]"
                    @update:options="onChartOptionsUpdate($event, item.item_no)"
                    @update:chart="setChartInstance($event, item.item_no)"
                />
            </div>
        </div>
    </transition>
    <div v-if="!computedData && loading" class="flex items-center justify-center px-4 py-16">
        <Spinner class="h-10 w-10" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { merge } from 'lodash-es';
import * as Highcharts from 'highcharts';
import { store } from '@/plugins/store';
import { ActionType } from '@/plugins/store/actions';
import { Dashboard, DashboardBurgerMenuOption, DashboardItem, DashboardSection, IconSource } from '@/types';
import { MutationType } from '@/plugins/store/mutations';
import Spinner from '@/components/ui/Spinner.vue';
import GenericDashboardElement from './GenericDashboardElement.vue';
import Icon from '@/components/icons/Icon.vue';
import ExpandCollapseTransition from '../ExpandCollapseTransition.vue';

interface Props {
    data: Dashboard | null;
    loading: boolean;
    fetchActionType: ActionType;
    stateMutationType: MutationType;
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
});

const chartInstances = ref<Record<number, Highcharts.Chart>>({});
const isChartsUpdating = ref(false);

function setChartInstance(instance: Highcharts.Chart, itemNo: number) {
    chartInstances.value[itemNo] = instance;
}

function setChartExtremes(event: any, updatedChartId: number) {
    if (event.trigger !== 'navigator' || isChartsUpdating.value) {
        return;
    }

    isChartsUpdating.value = true;

    for (const chartId in chartInstances.value) {
        if (chartId.toString() !== updatedChartId.toString() && chartInstances.value[chartId]) {
            chartInstances.value?.[chartId]?.xAxis?.[0]?.setExtremes(event.min, event.max);
            chartInstances.value?.[chartId]?.redraw();
        }
    }

    isChartsUpdating.value = false;
}

function handleLegendClick(legendId: string) {
    Object.values(chartInstances.value).forEach((chart) => {
        const series = chart.series?.find((s: any) => s.options && s.options.id === legendId);

        if (series) {
            if (series.visible) {
                series.hide();
            } else {
                series.show();
            }
        }
    });
}

function toggleSectionCollapse(sectionIndex: number) {
    const clonedData = props.data;

    if (!clonedData?.sections) {
        return;
    }

    const section = (clonedData.sections as DashboardSectionWithItems[])[sectionIndex];

    if (!section) {
        return;
    }

    (clonedData.sections as DashboardSectionWithItems[])[sectionIndex].is_collapsed = !section.is_collapsed;

    store.commit(props.stateMutationType, clonedData);
}

const loadingState = ref<Record<number, boolean>>([]);

async function onChartOptionsUpdate(payload: { options: DashboardBurgerMenuOption[]; hasChanged: boolean }, itemNo: number, sectionIndex?: number) {
    if (!payload.hasChanged) {
        return;
    }

    try {
        loadingState.value[itemNo] = true;

        const requestOptions = {
            initialRequest: false,
            filterItems: itemNo.toString(),
            filterOptions: payload.options.map((option) => option.query_parameter).join(','),
        };

        const data = await store.dispatch(props.fetchActionType, { options: requestOptions });

        const clonedData = props.data;

        if (!clonedData) {
            return;
        }

        if (sectionIndex) {
            (clonedData.sections as DashboardSectionWithItems[])[sectionIndex].items[itemNo] = data.items[0];
        } else {
            const itemIndex = clonedData.items.findIndex((item) => item.item_no === itemNo);

            clonedData.items.splice(itemIndex, 1, data.items[0]);
        }

        store.commit(props.stateMutationType, clonedData);
    } finally {
        loadingState.value[itemNo] = false;
    }
}

const DEFAULT_CHART_HEIGHT = 300; // pixels

function getChartItemWithOptions(item: DashboardItem) {
    if (item.chart_options.xAxis?.[0]) {
        item.chart_options.xAxis[0].events = {
            setExtremes: (event: Highcharts.AxisSetExtremesEventCallbackFunction) => setChartExtremes(event, item.item_no),
        };
    }

    return {
        ...item,
        chart_options: merge(item.chart_options, {
            chart: {
                height: DEFAULT_CHART_HEIGHT * (item.height || 1),
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick(event: Event) {
                            const legendId = (this as any).options.id;

                            if (!legendId.includes('generic-chart-modal')) {
                                handleLegendClick(legendId);

                                event?.preventDefault();
                            }
                        },
                    },
                },
            },
        }),
    };
}

type DashboardSectionWithItems = DashboardSection & { items: DashboardItem[] };

const computedData = computed(() => {
    if (!props.data) {
        return null;
    }

    const items = props.data.items.map((item) => {
        if (item.type === 'chart' && item.chart_options) {
            return getChartItemWithOptions(item);
        }

        return item;
    });

    if (props.data.sections?.length) {
        const sections = props.data.sections.map((section) => ({
            ...section,
            items: items.filter((item) => section.item_numbers?.includes(item.item_no)),
        }));

        return {
            sections,
        } as { sections: DashboardSectionWithItems[] };
    }

    return {
        ...store.state.dashboardOverview,
        items,
    };
});
</script>
