<template>
    <div class="w-full grid">
        <GenericChartModal v-if="expandable" :chart-module="chartModule" :options="options" :chart-state="chartState" :print-subtitle="printSubtitle" @close="onModalClose">
            <template #default="scope">
                <div class="relative grid">
                    <div
                        ref="chartElement"
                        class="w-full"
                        :class="{
                            'highcharts-dashboards-container': options?.value?.type === ChartType.Dashboard,
                        }"
                    ></div>
                    <div class="absolute top-0" :class="{ 'right-[48px]': hasOptions, 'right-2': !hasOptions }">
                        <button class="flex items-center justify-center p-1 rounded-full transition-colors hover:bg-gray-100" @click="openModal(scope.open)">
                            <Icon class="h-6 w-auto" :src="IconSource.SearchPlus"></Icon>
                        </button>
                    </div>
                </div>
            </template>
        </GenericChartModal>
        <div v-else ref="chartElement" class="w-full" :class="{ 'highcharts-dashboards-container': options?.value?.type === ChartType.Dashboard }"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch, ComputedRef, ref, onMounted, onBeforeUnmount, unref, nextTick } from 'vue';
import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import isEqual from 'lodash-es/isEqual';
import { chart, stockChart, Chart } from 'highcharts';
import { board } from '@highcharts/dashboards/dashboards';
import merge from 'lodash-es/merge';
import GenericChartModal from '@/components/charts/GenericChartModal.vue';
import { ChartType, IconSource } from '@/types';

export default defineComponent({
    components: { GenericChartModal },
    props: {
        options: {
            type: Object as () => ComputedRef<Highcharts.Options & { type?: ChartType }>,
            default: null,
        },
        chartModule: {
            type: Function,
            default: null,
        },
        expandable: {
            type: Boolean,
            default: true,
        },
        printSubtitle: {
            type: String as () => null | string,
            default: null,
        },
        destroyOnUpdate: {
            type: Boolean as () => boolean,
            default: false,
        },
        hasOptions: {
            type: Boolean as () => boolean,
            default: false,
        },
    },
    emits: ['ready', 'update'],
    setup(props, { emit }) {
        const chartElement = ref<null | HTMLElement>(null);

        HighchartsMore(Highcharts);
        if (props.chartModule) {
            props.chartModule(Highcharts);
        }

        const highcharts = ref<Chart | any>();

        let currentActiveOptions = null as null | Highcharts.Options;

        const chartState = ref<{
            options: Highcharts.Options;
            seriesVisibility: boolean[];
            navigator: {
                min: number | undefined;
                max: number | undefined;
            };
        }>();

        async function openModal(open: CallableFunction) {
            const chartInstance = highcharts.value as Chart;
            const series = chartInstance.series as Highcharts.Series[];

            const mergedOptions = merge(chartInstance.options, {
                series:
                    chartInstance.options?.series?.map((s) => ({
                        ...s,
                        id: `${s.id}-generic-chart-modal`,
                    })) || [],
            });

            const currentState = {
                options: mergedOptions,
                seriesVisibility: series.map((s) => s.visible),
                navigator: {
                    min: chartInstance.xAxis[0].min ?? undefined,
                    max: chartInstance.xAxis[0].max ?? undefined,
                },
            };

            chartState.value = currentState;

            await nextTick();

            open();
        }

        function onModalClose() {
            chartState.value = undefined;
        }

        onMounted(async () => {
            if (chartElement.value) {
                const unreffedOptions = unref(props.options);
                currentActiveOptions = unreffedOptions;

                if (unreffedOptions?.type === ChartType.Stock) {
                    highcharts.value = stockChart(chartElement.value, unreffedOptions);
                } else if (unreffedOptions?.type === ChartType.Dashboard) {
                    highcharts.value = board(chartElement.value, unreffedOptions as any);
                } else {
                    highcharts.value = chart(chartElement.value, unreffedOptions);
                }

                await nextTick();

                emit('ready', highcharts.value);
            }
        });

        onBeforeUnmount(() => {
            if (highcharts.value && highcharts.value.destroy) {
                highcharts.value.destroy();
            }
        });

        watch(
            () => props.options,
            async (newValue: ComputedRef<Highcharts.Options & { type?: ChartType }>) => {
                const unreffedOptions = unref(newValue);
                if (highcharts.value && currentActiveOptions && !isEqual(unreffedOptions, currentActiveOptions)) {
                    currentActiveOptions = unreffedOptions;

                    if (props.destroyOnUpdate && highcharts.value?.destroy && chartElement.value) {
                        highcharts.value.destroy();

                        if (unreffedOptions?.type === ChartType.Stock) {
                            highcharts.value = stockChart(chartElement.value, unreffedOptions);
                        } else if (unreffedOptions?.type === ChartType.Dashboard) {
                            highcharts.value = board(chartElement.value, unreffedOptions as any);
                        } else {
                            highcharts.value = chart(chartElement.value, unreffedOptions);
                        }
                    } else {
                        highcharts.value.update(unreffedOptions, true, true, true);
                    }

                    await nextTick();

                    emit('update', highcharts.value);
                }
            },
            { deep: true }
        );

        return { chartElement, highcharts, onMounted, onBeforeUnmount, ChartType, openModal, chartState, IconSource, onModalClose };
    },
});
</script>
